import React, { useContext, useState, useRef, useEffect, Fragment } from "react";
import { AlertCircle, CheckCircle, ArrowLeft, Download, Home } from 'lucide-react';
import { BrowserRouter as Router, Routes, Route, Link, Navigate, useParams, useNavigate, useLocation } from 'react-router-dom';
import { Menu, Transition, MenuButton, MenuItems, MenuItem } from '@headlessui/react';
import { AuthContext } from "./context/AuthContext";


const PaymentSuccess = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [paymentDetails, setPaymentDetails] = useState(null);
    const { logout, userId, firstName } = useContext(AuthContext);

    // Define subscription plans (should match with your payment form)
    const SUBSCRIPTION_PLANS = {
        monthly: {
            priceId: 'pri_01jfg8vz7t4z4vjt7cp23hfynp',
            amount: 19,
            interval: 'month'
        },
        yearly: {
            priceId: 'pri_01jfn92w9nzzma6qcyr8jqbaj4',
            amount: 199,
            interval: 'year'
        }
    };


    // In your success page useEffect:
    useEffect(() => {
        try {
            const checkoutData = JSON.parse(localStorage.getItem('paddleCheckoutData'));
            const selectedPlan = localStorage.getItem('selectedPlan') || 'monthly';

            console.log('Checkout data in success page:', checkoutData);

            if (!checkoutData) {
                throw new Error('No checkout data found');
            }

            // Extract data from Paddle's event data structure
            setPaymentDetails({
                transactionId: checkoutData.data?.transaction_id || 'N/A',
                status: checkoutData.data?.status || 'Failed',
                amount: checkoutData.data.totals?.total || `$${SUBSCRIPTION_PLANS[selectedPlan].amount}.00`,
                interval: SUBSCRIPTION_PLANS[selectedPlan].interval,
                currency: checkoutData.data?.currency || 'USD',
                plan: selectedPlan,
                email: checkoutData.data?.customer?.email || localStorage.getItem('username'),
                date: new Date().toLocaleDateString()
            });

        } catch (error) {
            console.error('Error loading payment details:', error);
            setPaymentDetails({
                error: true,
                message: 'Failed to load payment details'
            });
        }
    }, [navigate]);

    if (!paymentDetails) {
        return (
            <div className="flex h-screen items-center justify-center">
                <div className="text-center">Loading payment details...</div>
            </div>
        );
    }

    if (paymentDetails.error) {
        return (
            <div className="flex h-screen items-center justify-center">
                <div className="text-center text-red-600">
                    {paymentDetails.message}
                </div>
            </div>
        );
    }


    const handleLogout = async () => {
        localStorage.clear();
        await logout();
        navigate("/");
    };



    return (

        <div className="relative flex h-screen overflow-hidden justify-center">

            <div className="relative flex flex-col 
                w-full 
                max-w-[98%]            /* Default max width */
                
                2xl:max-w-[1820px]     /* 2XL screens */
                mx-auto 
                h-screen 
                overflow-hidden"
            >

                <nav className="bg-white border-b border-gray-200 px-4 py-2.5 shadow-sm relative z-50">
                    <div className="flex justify-between items-center w-full">
                        {/* Left side: Logo and Navigation */}
                        <div className="flex items-center gap-2">
                            <Link to="/" className="flex-shrink-0">
                                <h1 className="flex items-center text-5xl font-extrabold dark:text-white">
                                    SQL
                                    <span className="bg-blue-100 text-blue-800 text-xl font-semibold me-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ms-2">
                                        Guroo
                                        {firstName ? `- ${firstName}` : ""}
                                    </span>
                                </h1>
                            </Link>

                            {/* Navigation Buttons */}
                            {/* <div className="hidden sm:flex items-center gap-1">
                        <button
                            onClick={handlePrevPage}
                            className="p-2 rounded-lg hover:bg-gray-100 transition-colors"
                            aria-label="Previous page"
                        >
                            <FcPrevious className="text-xl" />
                        </button>
                        <button
                            onClick={handleNextPage}
                            className="p-2 rounded-lg hover:bg-gray-100 transition-colors"
                            aria-label="Next page"
                        >
                            <FcNext className="text-xl" />
                        </button>
                    </div> */}
                        </div>

                        {/* Right side: User Menu */}
                        <div className="flex items-center">
                            <Menu as="div" className="relative ml-3">
                                <MenuButton className="flex items-center gap-2 p-2 rounded-full hover:bg-gray-100 transition-colors">
                                    {/* User Avatar */}
                                    <div className="w-8 h-8 rounded-full bg-blue-500 flex items-center justify-center">
                                        <span className="text-white text-sm font-medium">
                                            {/* Get first letter of user's name */}
                                            {localStorage.getItem('firstName')?.charAt(0) || 'U'}
                                        </span>
                                    </div>
                                    {/* Dropdown Arrow */}
                                    <svg className="w-4 h-4 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                    </svg>
                                </MenuButton>

                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <MenuItems className="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        {/* User Info */}
                                        <div className="px-4 py-2 border-b border-gray-100">
                                            <p className="text-sm font-medium text-gray-900">
                                                {localStorage.getItem('firstName') || 'User'}
                                            </p>
                                            <p className="text-xs text-gray-500">
                                                {localStorage.getItem('username') || 'user@example.com'}
                                            </p>
                                        </div>

                                        {/* Menu Items */}
                                        <MenuItem>
                                            {({ active }) => (
                                                <Link
                                                    to="/profile"
                                                    className={`${active ? 'bg-gray-100' : ''
                                                        } flex items-center px-4 py-2 text-sm text-gray-700`}
                                                >
                                                    <svg className="mr-3 h-5 w-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                            d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                                                        />
                                                    </svg>
                                                    Profile
                                                </Link>
                                            )}
                                        </MenuItem>

                                        <MenuItem>
                                            {({ active }) => (
                                                <Link
                                                    to="/settings"
                                                    className={`${active ? 'bg-gray-100' : ''
                                                        } flex items-center px-4 py-2 text-sm text-gray-700`}
                                                >
                                                    <svg className="mr-3 h-5 w-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                            d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                                                        />
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                    </svg>
                                                    Settings
                                                </Link>
                                            )}
                                        </MenuItem>

                                        <div className="border-t border-gray-100">
                                            <MenuItem>
                                                {({ active }) => (
                                                    <button
                                                        onClick={handleLogout}

                                                        className={`${active ? 'bg-gray-100' : ''
                                                            } flex w-full items-center px-4 py-2 text-sm text-red-700`}
                                                    >
                                                        <svg className="mr-3 h-5 w-5 text-red-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                                d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                                                            />
                                                        </svg>
                                                        Sign out
                                                    </button>
                                                )}
                                            </MenuItem>
                                        </div>
                                    </MenuItems>
                                </Transition>
                            </Menu>
                        </div>
                    </div>
                </nav>


                <div className="relative flex h-screen overflow-hidden justify-center bg-gray-50">
                    <div className="relative flex flex-col w-full max-w-[98%] 2xl:max-w-[1820px] mx-auto h-screen overflow-hidden">


                        {/* Success Content */}
                        <div className="flex-1 flex items-center justify-center p-4">
                            <div className="max-w-md w-full bg-white rounded-lg shadow-lg p-8 space-y-6">
                                {/* Success Header */}
                                <div className="text-center space-y-4">
                                    <div className="flex justify-center">
                                        <CheckCircle className="w-16 h-16 text-green-500" />
                                    </div>
                                    <h2 className="text-2xl font-bold text-gray-900">Payment Successful!</h2>
                                    <p className="text-gray-600">
                                        Thank you for subscribing to SQL Guroo. Your account has been successfully activated.
                                    </p>
                                </div>


                                {/* Payment Details */}
                                <div className="bg-gray-50 rounded-lg p-6 space-y-3">
                                    <div className="flex justify-between items-center">
                                        <span className="text-gray-600">Amount Paid</span>
                                        <span className="font-semibold text-gray-900">{paymentDetails.amount}</span>
                                    </div>
                                    <div className="flex justify-between items-center">
                                        <span className="text-gray-600">Plan</span>
                                        <span className="font-semibold text-gray-900 capitalize">{paymentDetails.plan}</span>
                                    </div>
                                    <div className="flex justify-between items-center">
                                        <span className="text-gray-600">Transaction ID</span>
                                        <span className="text-gray-900">{paymentDetails.transactionId}</span>
                                    </div>
                                    <div className="flex justify-between items-center">
                                        <span className="text-gray-600">Email</span>
                                        <span className="text-gray-900">{paymentDetails.email}</span>
                                    </div>
                                    <div className="flex justify-between items-center">
                                        <span className="text-gray-600">Date</span>
                                        <span className="text-gray-900">{paymentDetails.date}</span>
                                    </div>
                                </div>

                                {/* Receipt Button */}
                                <button className="w-full flex items-center justify-center gap-2 py-2 px-4 bg-white border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 transition-colors">
                                    <Download className="w-5 h-5" />
                                    Download Receipt
                                </button>

                                {/* Navigation Buttons */}
                                <div className="grid grid-cols-2 gap-4">
                                    <button
                                        onClick={() => navigate(-1)}
                                        className="flex items-center justify-center gap-2 py-2 px-4 bg-gray-100 rounded-md text-gray-700 hover:bg-gray-200 transition-colors"
                                    >
                                        <ArrowLeft className="w-5 h-5" />
                                        Back
                                    </button>
                                    <Link
                                        to="/"
                                        className="flex items-center justify-center gap-2 py-2 px-4 bg-blue-600 rounded-md text-white hover:bg-blue-700 transition-colors"
                                    >
                                        <Home className="w-5 h-5" />
                                        Dashboard
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    );
};

export default PaymentSuccess;