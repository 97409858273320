// export default Register;

import React, { useState } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import Navbar from './Navbar'


// const URL = process.env.REACT_APP_BASE_URL;
// const URL = 'http://195.35.23.51:3001';
const URL = 'https://sqlguroo.com';




function Register() {
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
        phoneNumber: "",
        countryCode: "",
    });
    const [errors, setErrors] = useState({});
    const [submitError, setSubmitError] = useState('');
    const navigate = useNavigate();

    const validateEmail = (email) => {
        const re =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const validateForm = () => {
        let newErrors = {};
        if (!formData.firstName.trim())
            newErrors.firstName = "First name is required";
        if (!formData.lastName.trim()) newErrors.lastName = "Last name is required";
        if (!validateEmail(formData.email))
            newErrors.email = "Invalid email address";
        if (formData.password.length < 8)
            newErrors.password = "Password must be at least 8 characters long";
        if (formData.password !== formData.confirmPassword)
            newErrors.confirmPassword = "Passwords do not match";
        if (!formData.phoneNumber.trim())
            newErrors.phoneNumber = "Phone number is required";
        if (!formData.countryCode.trim())
            newErrors.countryCode = "Country code is required";

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            try {
                setSubmitError(''); // Clear any previous errors

                await axios.post(`${URL}/auth/register`, {
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    username: formData.email,
                    password: formData.password,
                    phoneNumber: formData.phoneNumber,
                    countryCode: formData.countryCode,
                });

                // Registration successful
                navigate("/login");
            } catch (error) {
                console.error("Registration failed:", error);

                // Handle specific error for existing email
                if (error.response?.status === 409) {
                    setSubmitError(error.response.data.message);
                    setErrors(prev => ({
                        ...prev,
                        email: 'This email is already registered'
                    }));
                } else {
                    setSubmitError('Registration failed. Please try again.');
                }
            }
        }
    };
    const handlePhoneNumberChange = (e) => {
        // Remove all non-digit characters
        let value = e.target.value.replace(/\D/g, '');

        // Limit to 10 digits
        value = value.slice(0, 10);

        // Format the number as XXX-XXX-XXXX
        if (value.length >= 6) {
            value = `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6)}`;
        } else if (value.length >= 3) {
            value = `${value.slice(0, 3)}-${value.slice(3)}`;
        }

        // Update form data
        setFormData(prev => ({
            ...prev,
            phoneNumber: value
        }));
    };

    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">

            <Navbar />



            <div className="w-full max-w-md">
                <form
                    onSubmit={handleSubmit}
                    className="bg-white p-8 rounded-lg shadow-lg"
                >
                    <h2 className="text-2xl font-semibold mb-6 text-gray-700">
                        Register
                    </h2>
                    {errors.form && (
                        <div className="mb-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded-md">
                            {errors.form}
                        </div>
                    )}
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-semibold mb-2">
                            First Name
                        </label>
                        <input
                            type="text"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            className={`w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 ${errors.firstName ? "border-red-500" : "border-gray-300"
                                }`}
                            required
                        />
                        {errors.firstName && (
                            <p className="mt-1 text-red-500 text-xs">{errors.firstName}</p>
                        )}
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-semibold mb-2">
                            Last Name
                        </label>
                        <input
                            type="text"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            className={`w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 ${errors.lastName ? "border-red-500" : "border-gray-300"
                                }`}
                            required
                        />
                        {errors.lastName && (
                            <p className="mt-1 text-red-500 text-xs">{errors.lastName}</p>
                        )}
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-semibold mb-2">
                            Email
                        </label>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className={`w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 ${errors.email ? "border-red-500" : "border-gray-300"
                                }`}
                            placeholder="you@example.com"
                            required
                        />
                        {errors.email && (
                            <p className="mt-1 text-red-500 text-xs">{errors.email}</p>
                        )}
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-semibold mb-2">
                            Password
                        </label>
                        <input
                            type="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            className={`w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 ${errors.password ? "border-red-500" : "border-gray-300"
                                }`}
                            placeholder="••••••••"
                            required
                        />
                        {errors.password && (
                            <p className="mt-1 text-red-500 text-xs">{errors.password}</p>
                        )}
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-semibold mb-2">
                            Confirm Password
                        </label>
                        <input
                            type="password"
                            name="confirmPassword"
                            value={formData.confirmPassword}
                            onChange={handleChange}
                            className={`w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 ${errors.confirmPassword ? "border-red-500" : "border-gray-300"
                                }`}
                            placeholder="••••••••"
                            required
                        />
                        {errors.confirmPassword && (
                            <p className="mt-1 text-red-500 text-xs">
                                {errors.confirmPassword}
                            </p>
                        )}
                    </div>
                    <div className="mb-4 flex">
                        <div className="w-1/3 mr-2">
                            <label className="block text-gray-700 text-sm font-semibold mb-2">
                                Country Code
                            </label>
                            <input
                                type="text"
                                name="countryCode"
                                value={formData.countryCode}
                                onChange={handleChange}
                                className={`w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 ${errors.countryCode ? "border-red-500" : "border-gray-300"
                                    }`}
                                placeholder="+1"
                                required
                            />
                            {errors.countryCode && (
                                <p className="mt-1 text-red-500 text-xs">
                                    {errors.countryCode}
                                </p>
                            )}
                        </div>
                        <div className="w-2/3">
                            <label className="block text-gray-700 text-sm font-semibold mb-2">
                                Phone Number
                            </label>
                            <input
                                type="tel"
                                name="phoneNumber"
                                value={formData.phoneNumber}
                                onChange={handlePhoneNumberChange}
                                className={`w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 ${errors.phoneNumber ? "border-red-500" : "border-gray-300"
                                    }`}
                                placeholder="123-456-7890"
                                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                maxLength="12"
                                required
                            />
                            {errors.phoneNumber && (
                                <p className="mt-1 text-red-500 text-xs">
                                    {errors.phoneNumber}
                                </p>
                            )}
                            <p className="mt-1 text-xs text-gray-500">
                                Format: XXX-XXX-XXXX
                            </p>
                        </div>
                    </div>
                    <button
                        type="submit"
                        className="w-full bg-blue-600 text-white py-3 px-4 rounded-md hover:bg-blue-700 transition duration-300 ease-in-out"
                    >
                        Register
                    </button>
                    <p className="mt-6 text-center text-gray-600">
                        Already have an account?{" "}
                        <Link to="/login" className="text-blue-600 hover:underline">
                            Login here
                        </Link>
                    </p>
                </form>
            </div>
        </div>
    );
}

export default Register;
