import React, { useContext, useState, useRef, useEffect, Fragment } from "react";
import { AlertCircle, CheckCircle } from 'lucide-react';
import { BrowserRouter as Router, Routes, Route, Link, Navigate, useParams, useNavigate } from 'react-router-dom';
import { Menu, Transition, MenuButton, MenuItems, MenuItem } from '@headlessui/react';
import { AuthContext } from "./context/AuthContext";
import axios, { AxiosResponse, AxiosError } from 'axios';

const PaddlePaymentForm = () => {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState('');
    const [paddleLoaded, setPaddleLoaded] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState('monthly'); // Default to monthly plan

    const { logout, userId, firstName } = useContext(AuthContext);
    const navigate = useNavigate();

    // Paddle credentials
    const PADDLE_CLIENT_TOKEN = 'test_c7c7f27e2debd9c9e1d5a35ce82';
    const PADDLE_PRICE_ID = 'pri_01jfg8vz7t4z4vjt7cp23hfynp';
    const PADDLE_SCRIPT_ID = 'paddle-js';


    // Define subscription plans
    const SUBSCRIPTION_PLANS = {
        monthly: {
            priceId: 'pri_01jfg8vz7t4z4vjt7cp23hfynp', // Replace with your monthly plan price ID
            amount: 19,
            interval: 'month'
        },
        yearly: {
            priceId: 'pri_01jfn92w9nzzma6qcyr8jqbaj4', // Replace with your yearly plan price ID
            amount: 199,
            interval: 'year'
        }
    };

    const handleCheckoutSuccess = async (checkoutData) => {
        try {
            const response = await axios.post('https://sqlguroo.com/api/subscriptions/create', {
                userId: userId,
                checkoutData: checkoutData.data,
                planType: checkoutData.data.items[0].price_name,
                email: checkoutData.data.customer.email,
                subscriptionId: checkoutData.data.transaction_id,
                startDate: new Date(),
                endDate: selectedPlan === 'monthly'
                    ? new Date(Date.now() + 30 * 24 * 60 * 60 * 1000)  // 30 days
                    : new Date(Date.now() + 365 * 24 * 60 * 60 * 1000) // 365 days
            });

            console.log('Subscription created:', response.data);

            // Redirect to success page
            window.location.href = `${window.location.origin}/paymentsuccess`;
        } catch (error) {
            console.error('Error creating subscription:', error);
            setError(error.response?.data?.message || 'Failed to create subscription. Please contact support.');
        }
    };


    useEffect(() => {
        const loadPaddle = async () => {
            try {
                const existingScript = document.getElementById(PADDLE_SCRIPT_ID);
                if (existingScript) return;

                const paddleScript = document.createElement('script');
                paddleScript.src = 'https://cdn.paddle.com/paddle/v2/paddle.js';
                paddleScript.id = PADDLE_SCRIPT_ID;
                paddleScript.async = true;

                const scriptLoaded = new Promise((resolve, reject) => {
                    paddleScript.addEventListener('load', resolve);
                    paddleScript.addEventListener('error', reject);
                });

                document.body.appendChild(paddleScript);

                await scriptLoaded;

                if (window.Paddle) {
                    window.Paddle.Environment.set('sandbox');
                    await window.Paddle.Setup({
                        token: PADDLE_CLIENT_TOKEN,
                        eventCallback: function (data) {
                            console.log("Paddle Event:", data);
                            if (data.name === "checkout.completed") {
                                console.log("Checkout completed:", data);
                                // Store the complete checkout data
                                localStorage.setItem('paddleCheckoutData', JSON.stringify(data));
                                localStorage.setItem('selectedPlan', selectedPlan);

                                // Send the checkout details to backend
                                handleCheckoutSuccess(data);

                                // Redirect to success page
                                // window.location.href = `${window.location.origin}/paymentsuccess`;
                            }
                        }
                    });
                    console.log('Paddle loaded and initialized');
                    setPaddleLoaded(true);
                }

            } catch (err) {
                console.error('Error loading Paddle:', err);
                setError('Failed to load payment system');
            }
        };

        loadPaddle();

        return () => {
            const scriptElement = document.getElementById(PADDLE_SCRIPT_ID);
            if (scriptElement && scriptElement.parentNode) {
                scriptElement.parentNode.removeChild(scriptElement);
            }
        };
    }, [selectedPlan]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            if (!window.Paddle) {
                throw new Error('Paddle SDK not loaded');
            }

            const email = e.target.email.value;
            const plan = SUBSCRIPTION_PLANS[selectedPlan];

            const checkoutOptions = {
                items: [{
                    priceId: plan.priceId,
                    quantity: 1
                }],
                customer: {
                    email: email
                },
                settings: {
                    displayMode: 'overlay',
                    theme: 'light',
                    locale: 'en',
                    successUrl: `${window.location.origin}/paymentsuccess`
                }
            };

            console.log('Opening checkout with:', checkoutOptions);
            await window.Paddle.Checkout.open(checkoutOptions);

        } catch (err) {
            console.error('Checkout error:', err);
            setError(`Payment failed: ${err.message}`);
        } finally {
            setLoading(false);
        }
    };




    const handleLogout = async () => {
        localStorage.clear();
        await logout();
        navigate("/");
    };



    return (

        <div className="relative flex h-screen overflow-hidden justify-center">

            <div className="relative flex flex-col 
                w-full 
                max-w-[98%]            /* Default max width */
                
                2xl:max-w-[1820px]     /* 2XL screens */
                mx-auto 
                h-screen 
                overflow-hidden"
            >

                <nav className="bg-white border-b border-gray-200 px-4 py-2.5 shadow-sm relative z-50">
                    <div className="flex justify-between items-center w-full">
                        {/* Left side: Logo and Navigation */}
                        <div className="flex items-center gap-2">
                            <Link to="/" className="flex-shrink-0">
                                <h1 className="flex items-center text-5xl font-extrabold dark:text-white">
                                    SQL
                                    <span className="bg-blue-100 text-blue-800 text-xl font-semibold me-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ms-2">
                                        Guroo
                                        {firstName ? `- ${firstName}` : ""}
                                    </span>
                                </h1>
                            </Link>

                            {/* Navigation Buttons */}
                            {/* <div className="hidden sm:flex items-center gap-1">
                        <button
                            onClick={handlePrevPage}
                            className="p-2 rounded-lg hover:bg-gray-100 transition-colors"
                            aria-label="Previous page"
                        >
                            <FcPrevious className="text-xl" />
                        </button>
                        <button
                            onClick={handleNextPage}
                            className="p-2 rounded-lg hover:bg-gray-100 transition-colors"
                            aria-label="Next page"
                        >
                            <FcNext className="text-xl" />
                        </button>
                    </div> */}
                        </div>

                        {/* Right side: User Menu */}
                        <div className="flex items-center">
                            <Menu as="div" className="relative ml-3">
                                <MenuButton className="flex items-center gap-2 p-2 rounded-full hover:bg-gray-100 transition-colors">
                                    {/* User Avatar */}
                                    <div className="w-8 h-8 rounded-full bg-blue-500 flex items-center justify-center">
                                        <span className="text-white text-sm font-medium">
                                            {/* Get first letter of user's name */}
                                            {localStorage.getItem('firstName')?.charAt(0) || 'U'}
                                        </span>
                                    </div>
                                    {/* Dropdown Arrow */}
                                    <svg className="w-4 h-4 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                    </svg>
                                </MenuButton>

                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <MenuItems className="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        {/* User Info */}
                                        <div className="px-4 py-2 border-b border-gray-100">
                                            <p className="text-sm font-medium text-gray-900">
                                                {localStorage.getItem('firstName') || 'User'}
                                            </p>
                                            <p className="text-xs text-gray-500">
                                                {localStorage.getItem('username') || 'user@example.com'}
                                            </p>
                                        </div>

                                        {/* Menu Items */}
                                        <MenuItem>
                                            {({ active }) => (
                                                <Link
                                                    to="/profile"
                                                    className={`${active ? 'bg-gray-100' : ''
                                                        } flex items-center px-4 py-2 text-sm text-gray-700`}
                                                >
                                                    <svg className="mr-3 h-5 w-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                            d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                                                        />
                                                    </svg>
                                                    Profile
                                                </Link>
                                            )}
                                        </MenuItem>

                                        <MenuItem>
                                            {({ active }) => (
                                                <Link
                                                    to="/settings"
                                                    className={`${active ? 'bg-gray-100' : ''
                                                        } flex items-center px-4 py-2 text-sm text-gray-700`}
                                                >
                                                    <svg className="mr-3 h-5 w-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                            d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                                                        />
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                    </svg>
                                                    Settings
                                                </Link>
                                            )}
                                        </MenuItem>

                                        <div className="border-t border-gray-100">
                                            <MenuItem>
                                                {({ active }) => (
                                                    <button
                                                        onClick={handleLogout}

                                                        className={`${active ? 'bg-gray-100' : ''
                                                            } flex w-full items-center px-4 py-2 text-sm text-red-700`}
                                                    >
                                                        <svg className="mr-3 h-5 w-5 text-red-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                                d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                                                            />
                                                        </svg>
                                                        Sign out
                                                    </button>
                                                )}
                                            </MenuItem>
                                        </div>
                                    </MenuItems>
                                </Transition>
                            </Menu>
                        </div>
                    </div>
                </nav>


                <div className="w-full max-w-md mx-auto bg-white shadow-lg rounded-lg">
                    <div className="p-6">
                        <h2 className="text-2xl font-bold mb-6">Choose Your Plan</h2>

                        <div className="space-y-6">
                            {/* Plan Selection */}
                            <div className="grid grid-cols-2 gap-4">
                                {/* Monthly Plan */}
                                <div
                                    className={`p-4 border rounded-lg cursor-pointer transition-all ${selectedPlan === 'monthly'
                                        ? 'border-blue-500 bg-blue-50'
                                        : 'border-gray-200 hover:border-blue-300'
                                        }`}
                                    onClick={() => setSelectedPlan('monthly')}
                                >
                                    <div className="flex items-center space-x-2">
                                        <input
                                            type="radio"
                                            checked={selectedPlan === 'monthly'}
                                            onChange={() => setSelectedPlan('monthly')}
                                            className="text-blue-600"
                                        />
                                        <div>
                                            <h3 className="font-medium">Monthly Plan</h3>
                                            <p className="text-xl font-bold">${SUBSCRIPTION_PLANS.monthly.amount}/month</p>
                                        </div>
                                    </div>
                                </div>

                                {/* Yearly Plan */}
                                <div
                                    className={`p-4 border rounded-lg cursor-pointer transition-all ${selectedPlan === 'yearly'
                                        ? 'border-blue-500 bg-blue-50'
                                        : 'border-gray-200 hover:border-blue-300'
                                        }`}
                                    onClick={() => setSelectedPlan('yearly')}
                                >
                                    <div className="flex items-center space-x-2">
                                        <input
                                            type="radio"
                                            checked={selectedPlan === 'yearly'}
                                            onChange={() => setSelectedPlan('yearly')}
                                            className="text-blue-600"
                                        />
                                        <div>
                                            <h3 className="font-medium">Yearly Plan</h3>
                                            <p className="text-xl font-bold">${SUBSCRIPTION_PLANS.yearly.amount}/year</p>
                                            <p className="text-md text-gray-500 line-through">$228/year</p>

                                            {/* <p className="text-sm text-green-600">Pay ${(SUBSCRIPTION_PLANS.yearly.amount / 12).toFixed(2)}/month</p> */}
                                            <p className="text-sm text-green-600">Save ${(SUBSCRIPTION_PLANS.monthly.amount * 12) - SUBSCRIPTION_PLANS.yearly.amount} yearly</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {!success ? (
                                <form onSubmit={handleSubmit} className="space-y-4">
                                    <div className="space-y-2">
                                        <label
                                            htmlFor="email"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            Email Address
                                        </label>
                                        <input
                                            id="email"
                                            name="email"
                                            type="email"
                                            placeholder="Enter your email"
                                            required
                                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                        />
                                    </div>

                                    {error && (
                                        <div className="flex items-center space-x-2 text-red-600">
                                            <AlertCircle size={16} />
                                            <span className="text-sm">{error}</span>
                                        </div>
                                    )}

                                    <button
                                        type="submit"
                                        disabled={loading || !paddleLoaded}
                                        className={`w-full py-2 px-4 rounded-md text-white font-medium
                                    ${loading || !paddleLoaded
                                                ? 'bg-gray-400 cursor-not-allowed'
                                                : 'bg-blue-600 hover:bg-blue-700'
                                            }`}
                                    >
                                        {loading
                                            ? 'Processing...'
                                            : `Pay Now $${SUBSCRIPTION_PLANS[selectedPlan].amount}/${SUBSCRIPTION_PLANS[selectedPlan].interval}`
                                        }
                                    </button>

                                    {!paddleLoaded && (
                                        <p className="text-sm text-gray-500 text-center">
                                            Loading payment system...
                                        </p>
                                    )}
                                </form>
                            ) : (
                                <div className="text-center space-y-4">
                                    <div className="flex justify-center">
                                        <CheckCircle className="h-12 w-12 text-green-500" />
                                    </div>
                                    <h3 className="text-lg font-medium">Payment Successful!</h3>
                                    <p className="text-gray-600">
                                        Thank you for your purchase. You will receive a confirmation email shortly.
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaddlePaymentForm;