import { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './context/AuthContext';

const GoogleCallback = () => {
    const { handleGoogleCallback } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        const handleCallback = async () => {
            try {
                await handleGoogleCallback();
                navigate('/'); // Redirect to dashboard after successful login
            } catch (error) {
                console.error('Google login failed:', error);
                navigate('/login'); // Redirect to login page if there's an error
            }
        };

        handleCallback();
    }, [handleGoogleCallback, navigate]);

    return (
        <div className="flex justify-center items-center h-screen">
            <div>Loading...</div>
        </div>
    );
};

export default GoogleCallback;