import React, { useContext, useState, useRef, useEffect, Fragment } from "react";
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from "./context/AuthContext";
import Topbar from './components/Topbar';
import { ChevronRight, Database, BarChart } from 'lucide-react';


const CasestudyCards = () => {
    const [questions, setQuestions] = useState([]);
    const [loading, setLoading] = useState(true);
    const { userId, firstName } = useContext(AuthContext);
    const navigate = useNavigate();


    const casestudies = [
        {
            id: 1,
            title: 'E-commerce Sales Analysis',
            description: 'Deep dive into sales performance, customer behavior, and revenue patterns.',
            icon: <BarChart className="text-blue-500" size={40} />,
            difficulty: 'Intermediate',
            tags: ['Sales', 'Aggregation', 'Joins']
        },
        {
            id: 2,
            title: 'Hospital Patient Management',
            description: 'Complex queries for tracking patient records, treatments, and resource allocation.',
            icon: <Database className="text-green-500" size={40} />,
            difficulty: 'Advanced',
            tags: ['Healthcare', 'Complex Queries', 'Reporting']
        },
        {
            id: 3,
            title: 'Social Media Engagement Metrics',
            description: 'Analyzing user interactions, content performance, and engagement rates.',
            icon: <BarChart className="text-purple-500" size={40} />,
            difficulty: 'Intermediate',
            tags: ['Analytics', 'Aggregation', 'Filtering']
        },
        {
            id: 4,
            title: 'Financial Transaction Tracking',
            description: 'Advanced financial database querying and complex transaction analysis.',
            icon: <Database className="text-indigo-500" size={40} />,
            difficulty: 'Advanced',
            tags: ['Finance', 'Complex Joins', 'Reporting']
        },
        {
            id: 5,
            title: 'Financial Transaction Tracking',
            description: 'Advanced financial database querying and complex transaction analysis.',
            icon: <Database className="text-indigo-500" size={40} />,
            difficulty: 'Advanced',
            tags: ['Finance', 'Complex Joins', 'Reporting']
        },
        {
            id: 6,
            title: 'Financial Transaction Tracking',
            description: 'Advanced financial database querying and complex transaction analysis.',
            icon: <Database className="text-indigo-500" size={40} />,
            difficulty: 'Advanced',
            tags: ['Finance', 'Complex Joins', 'Reporting']
        },
        {
            id: 7,
            title: 'Financial Transaction Tracking',
            description: 'Advanced financial database querying and complex transaction analysis.',
            icon: <Database className="text-indigo-500" size={40} />,
            difficulty: 'Advanced',
            tags: ['Finance', 'Complex Joins', 'Reporting']
        },
        {
            id: 8,
            title: 'Financial Transaction Tracking',
            description: 'Advanced financial database querying and complex transaction analysis.',
            icon: <Database className="text-indigo-500" size={40} />,
            difficulty: 'Advanced',
            tags: ['Finance', 'Complex Joins', 'Reporting']
        }
    ];

    const CaseStudyCard = ({ study }) => {
        const getDifficultyColor = (difficulty) => {
            switch (difficulty) {
                case 'Beginner': return 'bg-green-100 text-green-800';
                case 'Intermediate': return 'bg-blue-100 text-blue-800';
                case 'Advanced': return 'bg-red-100 text-red-800';
                default: return 'bg-gray-100 text-gray-800';
            }
        };

        return (
            <Link
                to={`/case-study/${study.id}`}
                className="group transform transition-all duration-300 hover:scale-105"
            >
                <div className="bg-white shadow-lg rounded-xl p-6 border border-gray-100 hover:border-blue-200 h-full flex flex-col">
                    <div className="flex justify-between items-center mb-4">
                        {study.icon}
                        <span className={`px-3 py-1 rounded-full text-xs font-semibold ${getDifficultyColor(study.difficulty)}`}>
                            {study.difficulty}
                        </span>
                    </div>

                    <h3 className="text-xl font-bold text-gray-800 mb-3 group-hover:text-blue-600 transition-colors">
                        {study.title}
                    </h3>

                    <p className="text-gray-600 mb-4 flex-grow">
                        {study.description}
                    </p>

                    <div className="flex flex-wrap gap-2 mb-4">
                        {study.tags.map((tag, index) => (
                            <span
                                key={index}
                                className="px-2 py-1 bg-gray-100 text-gray-700 text-xs rounded-full"
                            >
                                {tag}
                            </span>
                        ))}
                    </div>

                    <div className="flex items-center text-blue-600 font-semibold group-hover:text-blue-800 transition-colors">
                        View Case Study
                        <ChevronRight
                            size={20}
                            className="ml-2 group-hover:translate-x-1 transition-transform"
                        />
                    </div>
                </div>
            </Link>
        );
    };


    return (

        <div className="relative flex h-screen overflow-hidden justify-center">


            {/* <div className="relative flex flex-col w-10/12 h-screen overflow-hidden"> */}

            <div className="relative flex flex-col 
                w-full 
                max-w-[98%]            /* Default max width */
                
                2xl:max-w-[1820px]     /* 2XL screens */
                mx-auto 
                h-screen 
                overflow-hidden"
            >

                <Topbar />

                <div className="container mx-auto px-4 py-8 overflow-y-auto">
                    {/* Stats Summary */}
                    <div className="container mx-auto px-4 py-12">
                        <div className="text-center mb-12">
                            <h2 className="text-4xl font-bold text-gray-800 mb-4">
                                SQL Case Studies
                            </h2>
                            <p className="text-gray-600 max-w-2xl mx-auto">
                                Explore real-world SQL scenarios across various domains. Each case study challenges you to apply complex querying techniques and gain insights from diverse datasets.
                            </p>
                        </div>

                        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
                            {casestudies.map(study => (
                                <CaseStudyCard key={study.id} study={study} />
                            ))}
                        </div>
                    </div>                </div>
            </div>
        </div>
    );
};

export default CasestudyCards; 