import React, { useState, useContext } from "react";
import { AuthContext } from "./context/AuthContext";
import { useNavigate, Link } from "react-router-dom";
import logo from './logo_img.png';
import Navbar from './Navbar'
import Footer from './components/Footer';

function Pricing() {

    return (
        <div className="relative flex h-screen overflow-hidden justify-center">

            <div className="flex flex-col  h-screen w-10/12" >

                <div className="navbar bg-base-100 " >
                    <div className="navbar-start items-center">
                        <Link to="/" className="items-center flex flex-row">
                            <img src={logo} alt="Logo" className="w-1 sm:mx-auto" />
                        </Link>
                    </div>

                </div>



                <div className="flex flex-col h-full justify-items-center items-center  border border-gray-200 rounded-lg bg-white shadow-sm  font-semibold overflow-y-scroll" >

                    <div className="relative px-6 lg:px-8">
                        <div className="mx-auto max-w-7xl pt-6 pb-6 sm:pt-10 sm:pb-10 ">
                            <div className="bg-white py-4">
                                <div className="max-w-screen-xl mx-auto px-4 md:px-8">
                                    {/* Alert Banner */}
                                    <div className="flex justify-center mb-4">
                                        <a
                                            href="/"
                                            className="inline-flex justify-between items-center py-1 px-1 pr-4 mb-2 mt-6 text-sm text-gray-700 bg-gray-100 rounded-full dark:bg-gray-800 dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700"
                                            role="alert"
                                        >
                                            <span className="text-xl bg-blue-500 rounded-full text-white px-4 py-1.5 mr-3">
                                                New
                                            </span>{" "}
                                            <span className="text-xl font-medium">Coming Soon
                                            </span>

                                        </a>
                                    </div>

                                    {/* Pricing Header */}
                                    <div className="text-center mb-6">
                                        <h2 className="text-4xl font-bold text-gray-800">
                                            Choose a plan that fits
                                            <br className="hidden sm:block" />
                                            your needs
                                        </h2>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="mb-12 inline-block lg:flex lg:flex-col lg:justify-end lg:justify-self-end">
                                    <div className="lg:mt-auto">
                                        <div className="relative flex items-center justify-center rounded-lg border border-gray-200 bg-gray-50 p-[3px]">
                                            <div className="absolute left-[3px] top-[3px] h-[calc(100%-6px)] w-[calc(50%-3px)] rounded-lg bg-white shadow-md transition-transform duration-300 ease-in-out translate-x-full" />
                                            <button className="px-3 py-2 text-3.5 min-w-[180px] text-center relative z-10 transition-all duration-300 font-normal text-body-light-muted">
                                                Monthly
                                            </button>
                                            <button className="px-3 py-2 text-3.5 min-w-[180px] text-center relative z-10 transition-all duration-300 font-medium text-body-light-loud">
                                                Yearly
                                                <div className="text-3 ml-1 inline-block rounded-full bg-gradient-to-br from-theme-yellow via-theme-purple to-theme-red p-[1px] font-medium">
                                                    <p className="rounded-full bg-primary-200 px-3 py-1 text-sm font-semibold text-primary-700">
                                                        -20%
                                                    </p>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div> */}

                            <div className="mt-8 space-y-8 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:space-y-0">
                                <div className="relative flex flex-col rounded-2xl border border-gray-300 bg-gray-50 p-8">
                                    <h3 className="font-display text-4xl font-semibold text-gray-900">
                                        Monthly Essential
                                    </h3>

                                    <p className="mt-4 text-lg leading-tight text-gray-700">
                                        Monthly Plan for Students and starters
                                    </p>
                                    <p className="mt-6 flex items-baseline text-gray-900">
                                        <span className="font-display text-2xl">$19</span>{" "}
                                        <span
                                            className="ml-1 text-lg text-gray-800"
                                            data-svelte-h="svelte-lg8oie"
                                        >
                                            /month
                                        </span>
                                    </p>
                                    <h6 className="text-md mt-10 block font-display uppercase tracking-wide text-gray-600">
                                        Key Features
                                    </h6>
                                    <ul className="mt-6 flex-grow space-y-3">
                                        <li className="flex items-center text-gray-700">
                                            <svg
                                                width={16}
                                                height={11}
                                                viewBox="0 0 16 11"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M14.125 1.18164L5.375 9.93164L1 5.55664"
                                                    strokeWidth={2}
                                                    stroke="currentColor"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            <span className="ml-3 text-lg text-gray-800">
                                                Free for personal use
                                            </span>
                                        </li>
                                        <li className="flex items-center text-gray-700">
                                            <svg
                                                width={16}
                                                height={11}
                                                viewBox="0 0 16 11"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M14.125 1.18164L5.375 9.93164L1 5.55664"
                                                    strokeWidth={2}
                                                    stroke="currentColor"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            <span className="ml-3 text-lg text-gray-800">Learn Basics of SQL</span>
                                        </li>
                                        <li className="flex items-center text-gray-700">
                                            {/* <svg
                                                height={16}
                                                width={16}
                                                viewBox="3 3 13 13"
                                                fill="currentColor"
                                                aria-hidden="true"
                                            >
                                                <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                                            </svg> */}
                                            <svg
                                                width={16}
                                                height={11}
                                                viewBox="0 0 16 11"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M14.125 1.18164L5.375 9.93164L1 5.55664"
                                                    strokeWidth={2}
                                                    stroke="currentColor"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            <span className="ml-3 text-lg text-gray-800">Practice SQL Queries</span>
                                        </li>
                                        {/* <li className="flex items-center text-gray-700">
                                            <svg
                                                height={16}
                                                width={16}
                                                viewBox="3 3 13 13"
                                                fill="currentColor"
                                                aria-hidden="true"
                                            >
                                                <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                                            </svg>
                                            <span className="ml-3 text-lg text-gray-800">
                                                No Native Installers
                                            </span>
                                        </li> */}
                                    </ul>
                                    <a
                                        className="text-lg inline-block rounded-lg px-8 py-3 font-display font-semibold leading-7 shadow-sm ring-1 bg-white ring-gray-400 null hover:ring-gray-500 text-gray-900 mt-10 w-full text-center text-primary-700"
                                        style={{}}
                                        rel="null,"
                                        href="/payment"
                                    >
                                        Coming Soon{" "}
                                    </a>
                                </div>
                                <div className="relative flex flex-col rounded-2xl border border-gray-300 bg-gray-50 p-8">
                                    <h3 className="font-display text-4xl font-semibold text-gray-900">
                                        Yearly Essential
                                    </h3>
                                    <p className="mt-4 text-lg leading-tight text-gray-700">
                                        Yearly Plan for Students and starters
                                    </p>
                                    <p className="mt-6 flex items-baseline text-gray-900">
                                        <span className="font-display text-2xl">$199</span>{" "}
                                        <span
                                            className="ml-1 text-lg text-gray-800"
                                            data-svelte-h="svelte-lg8oie"
                                        >
                                            /year
                                        </span>
                                    </p>
                                    <h6 className="text-md mt-10 block font-display uppercase tracking-wide text-gray-600">
                                        Key Features
                                    </h6>
                                    <ul className="mt-6 flex-grow space-y-3">
                                        <li className="flex items-center text-gray-700">
                                            <svg
                                                width={16}
                                                height={11}
                                                viewBox="0 0 16 11"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M14.125 1.18164L5.375 9.93164L1 5.55664"
                                                    strokeWidth={2}
                                                    stroke="currentColor"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            <span className="ml-3 text-lg text-gray-800">
                                                Free for personal use
                                            </span>
                                        </li>
                                        <li className="flex items-center text-gray-700">
                                            <svg
                                                width={16}
                                                height={11}
                                                viewBox="0 0 16 11"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M14.125 1.18164L5.375 9.93164L1 5.55664"
                                                    strokeWidth={2}
                                                    stroke="currentColor"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            <span className="ml-3 text-lg text-gray-800">Learn Basics of SQL
                                            </span>
                                        </li>
                                        <li className="flex items-center text-gray-700">
                                            <svg
                                                width={16}
                                                height={11}
                                                viewBox="0 0 16 11"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M14.125 1.18164L5.375 9.93164L1 5.55664"
                                                    strokeWidth={2}
                                                    stroke="currentColor"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            <span className="ml-3 text-lg text-gray-800">
                                                Practice SQL Queries
                                            </span>
                                        </li>
                                        <li className="flex items-center text-gray-700">
                                            <svg
                                                width={16}
                                                height={11}
                                                viewBox="0 0 16 11"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M14.125 1.18164L5.375 9.93164L1 5.55664"
                                                    strokeWidth={2}
                                                    stroke="currentColor"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            <span className="ml-3 text-lg text-gray-800">
                                                Email/chat support
                                            </span>
                                        </li>
                                    </ul>
                                    <a
                                        className="text-lg inline-block rounded-lg px-8 py-3 font-display font-semibold leading-7 shadow-sm ring-1 bg-white ring-gray-400 null hover:ring-gray-500 text-gray-900 mt-10 w-full text-center text-primary-700"
                                        style={{}}
                                        rel="null,"
                                        href="/payment"
                                    >
                                        Coming Soon{" "}
                                    </a>
                                </div>
                                <div className="relative flex flex-col rounded-2xl border border-gray-300 bg-gray-50 p-8">
                                    {/* Most Popular Badge */}
                                    {/* <div
                                        className="absolute left-1/2 -translate-x-1/2 transform rounded-full bg-gradient-to-br from-theme-yellow via-theme-purple to-theme-red shadow-lg"
                                        style={{ padding: '2px 0', top: '-3px' }}
                                    >
                                        <p className="rounded-full bg-primary-200 px-4 py-1 text-sm font-semibold text-primary-700">
                                            Most Popular
                                        </p>
                                    </div> */}

                                    {/* Card Content */}
                                    <h3 className="font-display text-4xl font-semibold text-gray-900">
                                        Enterprise
                                    </h3>
                                    <p className="mt-4 text-lg leading-tight text-gray-700">
                                        For organizations and large enterprises
                                    </p>
                                    <p className="mt-6 flex items-baseline text-gray-900">
                                        <span className="font-display text-2xl">Contact Sales</span>
                                        <span
                                            className="ml-1 text-lg text-gray-800"
                                            data-svelte-h="svelte-lg8oie"
                                        >
                                            {/* /month */}
                                        </span>
                                    </p>
                                    <h6 className="text-md mt-10 block font-display uppercase tracking-wide text-gray-600">
                                        Everything in Essential Plus
                                    </h6>
                                    <ul className="mt-6 flex-grow space-y-3">

                                        <li className="flex items-center text-gray-700">
                                            <svg
                                                width={16}
                                                height={11}
                                                viewBox="0 0 16 11"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M14.125 1.18164L5.375 9.93164L1 5.55664"
                                                    strokeWidth={2}
                                                    stroke="currentColor"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            <span className="ml-3 text-lg text-gray-800">Analytics</span>
                                        </li>
                                        <li className="flex items-center text-gray-700">
                                            <svg
                                                width={16}
                                                height={11}
                                                viewBox="0 0 16 11"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M14.125 1.18164L5.375 9.93164L1 5.55664"
                                                    strokeWidth={2}
                                                    stroke="currentColor"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            <span className="ml-3 text-lg text-gray-800">
                                                Branded platform
                                            </span>
                                        </li>
                                        <li className="flex items-center text-gray-700">
                                            <svg
                                                width={16}
                                                height={11}
                                                viewBox="0 0 16 11"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M14.125 1.18164L5.375 9.93164L1 5.55664"
                                                    strokeWidth={2}
                                                    stroke="currentColor"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            <span className="ml-3 text-lg text-gray-800">
                                                Access to Expert Support
                                            </span>
                                        </li>
                                        <li className="flex items-center text-gray-700">
                                            <svg
                                                width={16}
                                                height={11}
                                                viewBox="0 0 16 11"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M14.125 1.18164L5.375 9.93164L1 5.55664"
                                                    strokeWidth={2}
                                                    stroke="currentColor"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            <span className="ml-3 text-lg text-gray-800">
                                                Request additional features
                                            </span>
                                        </li>
                                    </ul>
                                    {/* <a
                                        className="text-lg inline-block rounded-lg px-8 py-3 font-display font-semibold leading-7 shadow-sm ring-1 bg-primary-700 ring-primary-700 hover:bg-primary-600 hover:ring-primary-600 text-white mt-10 w-full text-center"
                                        href="/download"
                                    >
                                        Coming Soon
                                    </a> */}
                                    <a
                                        className="text-lg inline-block rounded-lg px-8 py-3 font-display font-semibold leading-7 shadow-sm ring-1 bg-white ring-gray-400 null hover:ring-gray-500 text-gray-900 mt-10 w-full text-center text-primary-700"
                                        style={{}}
                                        rel="null,"
                                        href="/download"
                                    >
                                        Coming Soon{" "}
                                    </a>
                                </div>

                            </div>
                            {/* <div className="mx-auto mt-16 max-w-2xl rounded-2xl bg-gray-900 ring-1 ring-gray-900 sm:mt-8 lg:mx-0 lg:flex lg:max-w-none">
                                <div className="p-8 sm:p-10 lg:flex-auto">
                                    <h6 className="text-md mb-3 block font-display uppercase tracking-wide text-gray-400">
                                        Dedicated support and expert guidance.
                                    </h6>
                                    <h3 className="font-display text-5xl font-semibold tracking-tight text-white sm:text-5xl">
                                        Enterprise
                                    </h3>
                                    <ul className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-200 sm:grid-cols-2 sm:gap-6">
                                        <li className="flex items-center gap-x-1 text-gray-200">
                                            <svg
                                                width={16}
                                                height={11}
                                                viewBox="0 0 16 11"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M14.125 1.18164L5.375 9.93164L1 5.55664"
                                                    strokeWidth={2}
                                                    stroke="currentColor"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            <span className="ml-3 text-lg text-gray-200">
                                                Whiteglove onboarding
                                            </span>
                                        </li>
                                        <li className="flex items-center gap-x-1 text-gray-200">
                                            <svg
                                                width={16}
                                                height={11}
                                                viewBox="0 0 16 11"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M14.125 1.18164L5.375 9.93164L1 5.55664"
                                                    strokeWidth={2}
                                                    stroke="currentColor"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            <span className="ml-3 text-lg text-gray-200">Custom features</span>
                                        </li>
                                        <li className="flex items-center gap-x-1 text-gray-200">
                                            <svg
                                                width={16}
                                                height={11}
                                                viewBox="0 0 16 11"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M14.125 1.18164L5.375 9.93164L1 5.55664"
                                                    strokeWidth={2}
                                                    stroke="currentColor"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            <span className="ml-3 text-lg text-gray-200">Priority support</span>
                                        </li>
                                        <li className="flex items-center gap-x-1 text-gray-200">
                                            <svg
                                                width={16}
                                                height={11}
                                                viewBox="0 0 16 11"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M14.125 1.18164L5.375 9.93164L1 5.55664"
                                                    strokeWidth={2}
                                                    stroke="currentColor"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            <span className="ml-3 text-lg text-gray-200">
                                                Shared Slack channel
                                            </span>
                                        </li>
                                        <li className="flex items-center gap-x-1 text-gray-200">
                                            <svg
                                                width={16}
                                                height={11}
                                                viewBox="0 0 16 11"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M14.125 1.18164L5.375 9.93164L1 5.55664"
                                                    strokeWidth={2}
                                                    stroke="currentColor"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            <span className="ml-3 text-lg text-gray-200">
                                                Dedicated account manager
                                            </span>
                                        </li>
                                        <li className="flex items-center gap-x-1 text-gray-200">
                                            <svg
                                                width={16}
                                                height={11}
                                                viewBox="0 0 16 11"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M14.125 1.18164L5.375 9.93164L1 5.55664"
                                                    strokeWidth={2}
                                                    stroke="currentColor"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            <span className="ml-3 text-lg text-gray-200">Custom Billing</span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="-mt-6 p-6 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
                                    <div className="rounded-xl bg-gray-100 py-8 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-10">
                                        <div className="mx-auto max-w-xs px-6">
                                            <p className="text-base font-semibold text-gray-600">
                                                Secure, reliable cross-platform desktop apps for enterprise teams.
                                            </p>
                                            <a
                                                href="/contact/sales"
                                                className="mt-10 block w-full rounded-lg bg-primary-700 px-8 py-3 text-center font-display text-lg text-white hover:bg-primary-600"
                                            >
                                                Contact Sales
                                            </a>
                                            <p className="mt-6 text-xs leading-5 text-gray-600">
                                                You may also live chat with us by{" "}
                                                <a
                                                    href="#intercom"
                                                    className="text-gray-800 underline"
                                                    data-svelte-h="svelte-1wio14i"
                                                >
                                                    clicking here
                                                </a>
                                                .
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>



                    <Navbar />




                    <Footer />





                </div >

            </div >





        </div >
    );
}

export default Pricing;
