import React, { useState } from 'react';
import axios from 'axios';

function CookieChecker() {
    const [sessionStatus, setSessionStatus] = useState(null);

    const checkSession = async () => {
        try {
            const response = await axios.get('/auth/status', {
                withCredentials: true
            });
            console.log('Session Check Response:', response.data);
            setSessionStatus({
                isAuthenticated: response.data.isAuthenticated,
                user: response.data.user
            });
        } catch (error) {
            console.log('Session Check Error:', error.response?.data || error);
            setSessionStatus({
                isAuthenticated: false,
                error: error.response?.data || 'Session check failed'
            });
        }
    };

    const checkBrowserStorage = () => {
        // Check visible cookies (non-httpOnly)
        console.log('Visible Cookies:', document.cookie);

        // Check localStorage
        console.log('LocalStorage:', {
            userId: localStorage.getItem('userId'),
            firstName: localStorage.getItem('firstName'),
            lastName: localStorage.getItem('lastName'),
            username: localStorage.getItem('username'),
            paidUser: localStorage.getItem('paidUser')
        });
    };

    return (
        <div style={{ padding: '20px' }}>
            <div style={{ marginBottom: '10px' }}>
                <button onClick={checkSession}>Check Session Status</button>
                {sessionStatus && (
                    <pre>
                        {JSON.stringify(sessionStatus, null, 2)}
                    </pre>
                )}
            </div>

            <div>
                <button onClick={checkBrowserStorage}>
                    Check Browser Storage
                </button>
            </div>
        </div>
    );
}

export default CookieChecker;