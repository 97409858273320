import React, { useContext, useState, useRef, useEffect, Fragment } from "react";
import './App.css';
import axios, { AxiosResponse, AxiosError } from 'axios';
import CodeMirror from '@uiw/react-codemirror';
import { createTheme } from '@uiw/codemirror-themes';
import { sql, SQLDialect } from "@codemirror/lang-sql";
import { EditorView, keymap } from "@codemirror/view";
import Split from 'react-split'
import DataTable from './DataTable'; // Import your DataTable component
import { BrowserRouter as Router, Routes, Route, Link, Navigate, useParams } from 'react-router-dom';
import Home from './Home';
// const deepEqual = require('deep-equal');
// var deepEqual = require('fast-deep-equal');
import { FcPrevious, FcNext } from 'react-icons/fc';
import { BsListCheck } from 'react-icons/bs';
import { BiLogoPostgresql } from "react-icons/bi";
import { SiPostgresql } from "react-icons/si";
import { ToastContainer, toast } from 'react-toastify';
import { IoArrowBack } from "react-icons/io5";
import { IoArrowForward } from "react-icons/io5";
import { IoKey } from "react-icons/io5";
import { qs } from "qs"
import Topbar from './components/Topbar';
import 'react-toastify/dist/ReactToastify.css';
import logo from './logo_img.png';
import { AuthContext } from "./context/AuthContext";
import ProfessionalSQLEditor from "./ProfessionalSQLEditor";
import FlexibleChart from './FlexibleChart';

function SubscribersMain() {
    const [sqlQuery, setSqlQuery] = useState('');
    const [results, setResults] = useState([]);
    const [correct, setCorrect] = useState('');
    const [loading, setLoading] = useState(true);
    const [question, setQuestion] = useState();
    const [queryerror, setQueryerror] = useState('');
    const { id } = useParams();
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 640);
    const [submissionResult, setSubmissionResult] = useState(null);
    const { userId, firstName } = useContext(AuthContext);
    const [isSolved, setIsSolved] = useState(false);
    const [viewMode, setViewMode] = useState('table'); // Add state for view mode

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 640);
        };

        // Attach the event listener
        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty dependency array ensures that the effect runs only once on mount



    // const backendUrl = 'https://sqlguru-service.onrender.com';
    const backendUrl = 'http://195.35.23.51:3001';



    const handlePrevPage = () => {
        // Assuming page is a string, you might want to convert it to a number
        const prevPage = parseInt(id, 10) - 1;
        // Redirect to the next page
        if (prevPage < 1) {
            return;
        }
        window.location.href = `/subscribersmain/${prevPage}`;
    };

    const handleNextPage = () => {
        // Assuming page is a string, you might want to convert it to a number
        const nextPage = parseInt(id, 10) + 1;
        // Redirect to the next page
        if (nextPage >= 10000) {
            return;
        }
        window.location.href = `/subscribersmain/${nextPage}`;
    };



    const handleQueryChange = (e) => {
        setSqlQuery(e);
        console.log('handleQueryChange - sqlQuery', sqlQuery)
    };



    const getItemData = async () => {
        try {
            const response = await axios.get(`https://sqlguroo.com/api/get-question/${id}`);
            const specificData = response.data;
            setQuestion(specificData);
            console.log(specificData);
        } catch (error) {
            console.error('Error fetching question data:', error);
        }
    };




    const executeQuery = async (e) => {
        e.preventDefault();  // Prevent default button behavior

        // Reset previous state
        setLoading(true);
        setQueryerror('');
        setButtonDisabled(true);
        setResults(null);
        setSubmissionResult(null);

        // Sanitization timeout
        const sanitizationDelay = 1400;

        setTimeout(async () => {
            console.log('sqlQuery', sqlQuery)

            try {
                // Configurable API endpoint
                const response = await axios.post(
                    'https://sqlguroo.com/subapi/submit-text',
                    { sqlQuery },
                    {
                        timeout: 15000, // 5 seconds timeout
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }
                );

                console.log('response from executeQuery', response)

                // Successful query execution
                setLoading(false);
                setResults(response.data);
                setQueryerror('');
            } catch (error) {
                console.log('error in executeQuery', error)
                // Comprehensive error handling
                if (axios.isCancel(error)) {
                    // Request cancellation
                    setQueryerror('Request was cancelled');
                } else if (error.response) {
                    // Server responded with an error
                    setQueryerror(
                        error.response.data.message ||
                        error.response.data.error ||
                        error.response.data ||
                        'An error occurred while executing the query'
                    );
                } else if (error.request) {
                    // Request made but no response received
                    setQueryerror('No response received from server');
                } else {
                    // General error
                    setQueryerror('An unexpected error occurred');
                }

                // Reset results
                setResults(null);
            } finally {
                // Always enable button after request
                setButtonDisabled(false);
            }
        }, sanitizationDelay);
    };



    // Add new submit function
    const submitQuery = async (e) => {
        e.preventDefault();
        // setIsSubmitting(true);
        setButtonDisabled(true)
        setSubmissionResult(null);
        setQueryerror('');

        try {
            const response = await axios.post(
                'https://sqlguroo.com/subapi/submit-solution',
                {
                    sqlQuery,
                    questionId: id, // Make sure you have access to the question ID,
                    userId
                },
                {
                    timeout: 15000,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );

            setSubmissionResult(response.data);
            console.log('submissionResult', response.data)

            // Handle success feedback
            if (response.data.isCorrect) {
                // Maybe trigger confetti or some celebration
                setQueryerror('');
            } else {
                setQueryerror('Your solution is not correct. Try again!');
            }

        } catch (error) {
            console.error('Submission error:', error);
            setQueryerror(
                error.response?.data?.error ||
                'An error occurred while submitting your solution'
            );
        } finally {
            // setIsSubmitting(false);
            setButtonDisabled(false)
        }
    };



    useEffect(() => {
        getItemData()
    }
        , [id]);




    // Add this function to check solved status
    const checkSolvedStatus = async () => {
        try {
            const response = await axios.get('https://sqlguroo.com/subapi/check-solved', {
                params: {
                    questionId: id,
                    userId: userId
                }
            });
            console.log('response from checkSolvedStatus', response)
            setIsSolved(response.data.isSolved);
        } catch (error) {
            console.error('Error checking solved status:', error);
        }
    };

    // Call this in useEffect when question loads
    useEffect(() => {
        if (id && userId) {
            checkSolvedStatus();
        }
    }, [id, userId]);


    const KEYWORDS = [
        "select",
        "from",
        "where",
        "and",
        "or",
        "not",
        "in",
        "between",
        "contains",
        "array",
        "limit",
        "offset",
        "union",
        "intersect",
        "except",
        "order by",
        "asc",
        "desc"
    ];

    const TENSORS = ["images", "labels"];

    const dialect = SQLDialect.define({
        keywords: KEYWORDS.join(" "),
        builtin: TENSORS.join(" ")
    });

    const sampleData = [
        { month: 1, sales: 100 },
        { month: 2, sales: 150 },
        { month: 3, sales: 120 }
    ];

    return (
        <div className="relative flex h-screen overflow-hidden justify-center">

            <div className="relative flex flex-col 
                    w-full 
                    max-w-[98%]            /* Default max width */
                    
                    2xl:max-w-[1820px]     /* 2XL screens */
                    mx-auto 
                    h-screen 
                    overflow-hidden"
            >

                <Topbar />

                <Split
                    // class="wrap"
                    sizes={[40, 60]}
                    minSize={100}
                    expandToMin={false}
                    gutterSize={10}
                    gutterAlign="center"
                    snapOffset={30}
                    dragInterval={1}
                    direction="horizontal"
                    cursor="col-resize"
                    className="split flex flex-row overflow-hidden h-full"
                >

                    <div className="border border-gray-200 rounded-md bg-white shadow-sm p-1 overflow-auto">

                        <div className="flex flex-row justify-between items-center gap-3 p-2 overflow-auto">
                            {/* All Questions Button */}
                            <Link to="/questionsoverview">
                                <button type="button" className="inline-flex items-center gap-2 px-3 py-2 bg-gray-100 hover:bg-gray-200 text-gray-700 rounded-lg transition-colors text-sm xl:text-base">
                                    <BsListCheck className="text-lg" />
                                    <span className="hidden 2xl:inline">
                                        All Questions
                                    </span>
                                </button>
                            </Link>

                            {/* Solution Button */}
                            <button
                                onClick={() => document.getElementById('my_modal_2').showModal()}
                                className="inline-flex items-center gap-2 px-3 py-2 bg-emerald-500 hover:bg-emerald-600 text-white rounded-lg transition-colors text-sm xl:text-base"
                            >
                                <IoKey className="text-lg" />
                                <span>Solution</span>
                            </button>

                            {/* Case study Button */}
                            <button
                                onClick={() => document.getElementById('my_modal_4').showModal()}
                                className="group relative inline-flex items-center gap-2 px-4 py-2 bg-gradient-to-r from-purple-500 to-indigo-600 hover:from-purple-600 hover:to-indigo-700 text-white rounded-lg transition-all duration-200 shadow-md hover:shadow-lg"
                            >
                                <svg
                                    className="w-5 h-5 transition-transform group-hover:rotate-12"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                                    />
                                </svg>
                                <span className="font-medium">Case Study</span>
                            </button>

                            {/* Solution Modal */}
                            <dialog id="my_modal_2" className="modal"
                                onClick={(e) => {
                                    if (e.target === e.currentTarget) {
                                        document.getElementById('my_modal_2').close();
                                    }
                                }}
                            >
                                <div className="modal-box max-w-3xl bg-white rounded-lg shadow-lg">
                                    <div className="flex items-center justify-between mb-4">
                                        <h3 className="text-xl font-semibold text-gray-800">Solution</h3>
                                        <form method="dialog">
                                            <button className="btn btn-sm btn-circle btn-ghost hover:bg-gray-100">✕</button>
                                        </form>
                                    </div>

                                    <div className="bg-gray-50 rounded-lg p-1">
                                        <CodeMirror
                                            className="min-h-[14rem] rounded-md overflow-auto"
                                            extensions={[sql({ dialect }), EditorView.lineWrapping]}
                                            height="14rem"
                                            value={question ? question.solution : ''}
                                            options={{ readOnly: true }}
                                            theme="dark"
                                        />
                                    </div>
                                </div>
                            </dialog>


                            {/* Case study Modal */}
                            <dialog
                                id="my_modal_4"
                                className="modal"
                                onClick={(e) => {
                                    if (e.target === e.currentTarget) {
                                        document.getElementById('my_modal_4').close();
                                    }
                                }}
                            >
                                <div className="modal-box max-w-4xl bg-white rounded-lg shadow-2xl">
                                    {/* Header Section */}
                                    <div className="bg-gradient-to-r from-blue-500 to-blue-600 p-6 rounded-t-lg">
                                        <div className="flex justify-between items-center">
                                            <h3 className="text-xl font-bold text-white flex items-center gap-2">
                                                <svg
                                                    className="w-6 h-6"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
                                                    />
                                                </svg>
                                                Context of this Case Study
                                            </h3>
                                            <button
                                                onClick={() => document.getElementById('my_modal_4').close()}
                                                className="btn btn-sm btn-circle btn-ghost absolute right-4 top-4 bg-gray-400 text-white hover:bg-blue-600"
                                            >
                                                ✕
                                            </button>
                                        </div>
                                    </div>

                                    {/* Image Section */}
                                    <div className="p-6 border-b border-gray-200">
                                        <div className="w-full h-48 md:h-64 lg:h-80 rounded-lg overflow-hidden bg-gray-100">
                                            {question?.image_url ? (
                                                <img
                                                    src={question.image_url}
                                                    alt="Case Study Diagram"
                                                    className="w-full h-full object-contain"
                                                    onError={(e) => {
                                                        e.target.onerror = null;
                                                        e.target.src = 'path/to/fallback/image.png'; // Add a fallback image path
                                                    }}
                                                />
                                            ) : (
                                                <div className="w-full h-full flex items-center justify-center text-gray-400">
                                                    <svg className="w-12 h-12" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                            d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                                    </svg>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    {/* Content Section */}
                                    <div className="p-6">
                                        <div className="bg-gray-50 p-5 rounded-lg border border-gray-100 shadow-inner">
                                            <p className="text-gray-700 text-lg leading-relaxed whitespace-pre-wrap">
                                                {question ? question.case_study : 'Loading question...'}
                                            </p>
                                        </div>

                                        {/* Footer Section */}
                                        <div className="mt-6 flex justify-end gap-3">
                                            <button
                                                onClick={() => document.getElementById('my_modal_4').close()}
                                                className="px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white font-semibold rounded-lg transition-colors flex items-center gap-2"
                                            >
                                                Let's Go!
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </dialog>


                            {/* PostgreSQL Syntax Button */}
                            <button
                                onClick={() => document.getElementById('my_modal_3').showModal()}
                                className="inline-flex items-center gap-2 px-3 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-lg transition-colors text-sm xl:text-base"
                            >
                                <SiPostgresql className="text-lg" />
                                <span>PostgreSQL</span>
                            </button>


                            {/* PostgreSQL Syntax Modal */}
                            <dialog id="my_modal_3" className="modal"
                                onClick={(e) => {
                                    if (e.target === e.currentTarget) {
                                        document.getElementById('my_modal_3').close();
                                    }
                                }}
                            >
                                <div className="modal-box max-w-2xl bg-white rounded-lg shadow-lg">
                                    <div className="flex items-center justify-between mb-4">
                                        <h3 className="text-xl font-semibold text-gray-800">PostgreSQL Syntax</h3>
                                        <form method="dialog">
                                            <button className="btn btn-sm btn-circle btn-ghost hover:bg-gray-100">✕</button>
                                        </form>
                                    </div>

                                    <div className="space-y-4 text-gray-700">
                                        <div className="p-3 bg-gray-50 rounded-lg">
                                            <p className="font-medium">1. Use PostgreSQL 14 version for SQL syntax</p>
                                        </div>
                                        <div className="p-3 bg-gray-50 rounded-lg">
                                            <p className="font-medium">2. To apply date condition use, - date_column = date '2020-01-01'</p>
                                        </div>
                                        <div className="p-3 bg-gray-50 rounded-lg">
                                            <p className="font-medium">3. To get the month from date use - extract(month from date_column)</p>
                                        </div>
                                    </div>
                                </div>
                            </dialog>
                        </div>



                        <div className="max-w-6xl mx-auto p-1 bg-white rounded-lg shadow-sm">
                            {/* Question Section */}
                            <div className="mb-8 space-y-2">
                                <p className="text-lg font-semibold text-gray-800">
                                    {id}. - {question ? question.question : ''}
                                </p>
                                <div className="prose max-w-none text-gray-600">
                                    {question ? question.html : ''}
                                </div>
                            </div>

                            {/* Schema Section */}
                            <div className="mb-8">
                                <div className="bg-gray-100 border border-gray-400 rounded-md px-4 py-2 mb-3 shadow-sm">
                                    <h2 className="text-lg font-semibold text-gray-800 text-center">1. Schema</h2>
                                </div>
                                <div className="space-y-6">
                                    {/* First Schema */}
                                    <div className="bg-gray-50 p-4 rounded-lg">
                                        <div className="bg-gray-50 border-l-4 border-blue-500 rounded-md px-4 py-2 mb-2 shadow-sm">
                                            <h3 className="text-lg font-medium text-gray-700">
                                                {question?.table_schema1 && question.table_schema1}
                                            </h3>
                                        </div>


                                        <div className="bg-white rounded-lg shadow-sm overflow-auto">
                                            {question ?
                                                <DataTable data={question.schema} />
                                                : <span className="loading loading-infinity loading-lg"></span>
                                            }
                                        </div>
                                    </div>

                                    {/* Second Schema */}
                                    {question && question.hasOwnProperty('schema2') && question.schema2 && (
                                        <div className="bg-gray-50 p-4 rounded-lg">
                                            <div className="bg-gray-50 border-l-4 border-blue-500 rounded-md px-4 py-2 mb-2 shadow-sm">
                                                <h3 className="text-lg font-medium text-gray-700">
                                                    {question.table_schema2}
                                                </h3>
                                            </div>

                                            <div className="bg-white rounded-lg shadow-sm overflow-auto">
                                                <DataTable data={question.schema2} />
                                            </div>
                                        </div>
                                    )}

                                    {/* Third Schema */}
                                    {question && question.hasOwnProperty('schema3') && question.schema3 && (
                                        <div className="bg-gray-50 p-4 rounded-lg">
                                            <div className="bg-gray-50 border-l-4 border-blue-500 rounded-md px-4 py-2 mb-2 shadow-sm">
                                                <h3 className="text-lg font-medium text-gray-700">
                                                    {question.table_schema3}
                                                </h3>
                                            </div>
                                            <div className="bg-white rounded-lg shadow-sm overflow-auto">
                                                <DataTable data={question.schema3} />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* Data Table Section */}
                            <div className="mb-8">
                                <div className="bg-gray-100 border border-gray-300 rounded-md px-4 py-2 mb-3 shadow-sm">
                                    <h2 className="text-lg font-semibold text-gray-800 text-center">2. Data Table</h2>
                                </div>

                                <div className="space-y-6">
                                    {/* First Table */}
                                    <div className="bg-gray-50 p-4 rounded-lg">
                                        <div className="bg-gray-50 border-l-4 border-blue-500 rounded-md px-4 py-2 mb-2 shadow-sm">
                                            <h3 className="text-lg font-medium text-gray-700">
                                                {question && question.hasOwnProperty('table_name1') && question.table_name1}
                                            </h3>
                                        </div>
                                        <div className="bg-white rounded-lg shadow-sm overflow-auto">
                                            {question ?
                                                <DataTable data={question.table} />
                                                : <span className="loading loading-infinity loading-lg"></span>
                                            }
                                        </div>
                                    </div>

                                    {/* Second Table */}
                                    {question && question.hasOwnProperty('table2') && question.table2 && (
                                        <div className="bg-gray-50 p-4 rounded-lg">
                                            <div className="bg-gray-50 border-l-4 border-blue-500 rounded-md px-4 py-2 mb-2 shadow-sm">
                                                <h3 className="text-lg font-medium text-gray-700">
                                                    {question.table_name2}
                                                </h3>
                                            </div>
                                            <div className="bg-white rounded-lg shadow-sm overflow-auto">
                                                <DataTable data={question.table2} />
                                            </div>
                                        </div>
                                    )}

                                    {/* Third Table */}
                                    {question && question.hasOwnProperty('table3') && question.table3 && (
                                        <div className="bg-gray-50 p-4 rounded-lg">
                                            <div className="bg-gray-50 border-l-4 border-blue-500 rounded-md px-4 py-2 mb-2 shadow-sm">
                                                <h3 className="text-lg font-medium text-gray-700">
                                                    {question.table_name3}
                                                </h3>
                                            </div>
                                            <div className="bg-white rounded-lg shadow-sm overflow-auto">
                                                <DataTable data={question.table3} />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* Example Output Section */}
                            <div>
                                <div className="bg-gray-100 border border-gray-300 rounded-md px-4 py-2 mb-3 shadow-sm">
                                    <h2 className="text-lg font-semibold text-gray-800 text-center">3. Example Output</h2>
                                </div>

                                <div className="bg-gray-50 p-4 rounded-lg">
                                    <div className="bg-white rounded-lg shadow-sm overflow-auto">
                                        {question ?
                                            <DataTable data={question.example_output} />
                                            : <span className="loading loading-infinity loading-lg"></span>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                    <div className="flex flex-col h-full bg-gray-100 p-1">
                        {/* <div className="flex-1 bg-white rounded-lg shadow-lg overflow-hidden"> */}
                        <div className="border border-gray-200 rounded-md bg-white shadow-sm p-1 h-full">

                            <Split
                                sizes={[50, 50]}
                                minSize={200}
                                expandToMin={false}
                                gutterSize={8}
                                gutterAlign="center"
                                snapOffset={30}
                                dragInterval={1}
                                direction="vertical"
                                cursor="row-resize"
                                className="overflow-hidden h-full"
                            >
                                {/* Top Section: SQL Editor */}
                                {/* <div className="flex flex-col h-full"> */}
                                <div className="border border-gray-300 rounded-md bg-white shadow-sm flex flex-col h-full">

                                    {/* Editor Header */}
                                    <div className="bg-white border-b border-gray-200 px-6 py-4 flex items-center justify-between">
                                        <div className="flex items-center space-x-4">
                                            <h2 className="text-lg font-semibold text-gray-800">SQL Playground</h2>
                                            <span className="px-2 py-1 bg-blue-100 text-blue-700 text-xs font-medium rounded">
                                                Exercise Mode
                                            </span>
                                            {isSolved && (
                                                <span className="px-3 py-1 bg-green-200 text-green-700 text-md font-medium rounded">
                                                    Solved ✓
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    {/* Code Editor */}
                                    {/* <div className="flex-1 bg-[#f8f9fc]"> */}
                                    <div className="flex-grow relative min-h-0">

                                        <CodeMirror
                                            className="absolute inset-0 overflow-auto rounded-md"
                                            indentWithTab={false}
                                            extensions={[sql({ dialect })]}
                                            placeholder="Write your SQL query here..."
                                            value={sqlQuery}
                                            onChange={handleQueryChange}
                                            theme="light"
                                            basicSetup={{
                                                lineNumbers: true,
                                                highlightActiveLineGutter: true,
                                                highlightSpecialChars: true,
                                                foldGutter: true,
                                                dropCursor: true,
                                                allowMultipleSelections: true,
                                                indentOnInput: true,
                                                bracketMatching: true,
                                                closeBrackets: true,
                                                autocompletion: true,
                                                rectangularSelection: true,
                                                crosshairCursor: true,
                                                highlightActiveLine: true,
                                                highlightSelectionMatches: true,
                                                closeBracketsKeymap: true,
                                                defaultKeymap: true,
                                                searchKeymap: true,
                                                historyKeymap: true,
                                                foldKeymap: true,
                                                completionKeymap: true,
                                                lintKeymap: true,
                                            }}
                                        />
                                    </div>

                                    {/* <div className="h-[500px]">
                                        <ProfessionalSQLEditor
                                            initialQuery="SELECT * FROM users"
                                            // onChange={handleQueryChange}
                                            onQueryChange={handleQueryChange}
                                            onExecute={handleQueryChange}
                                            dialect="postgresql"
                                        />
                                    </div> */}

                                    {/* Action Bar */}
                                    <div className="bg-white border-t border-gray-200 px-6 py-4">
                                        <div className="flex items-center justify-between">
                                            {/* Status Messages */}
                                            <div className="flex-1 mr-6">
                                                {isButtonDisabled ? (
                                                    <div className="flex items-center space-x-2">
                                                        <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-gray-900"></div>
                                                        <span className="text-sm text-gray-600">Processing...</span>
                                                    </div>
                                                ) : (
                                                    <div className="text-sm">
                                                        {submissionResult?.success && (
                                                            <div className={`flex items-center space-x-2 p-3 rounded-md ${submissionResult.isCorrect
                                                                ? 'bg-green-50 text-green-700 border border-green-200'
                                                                : 'bg-red-50 text-red-700 border border-red-200'
                                                                }`}>
                                                                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                                                                    <path fillRule="evenodd" d={
                                                                        submissionResult.isCorrect
                                                                            ? "M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                                                            : "M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                                                                    } clipRule="evenodd" />
                                                                </svg>
                                                                <span className="font-medium">{submissionResult.feedback}</span>
                                                            </div>
                                                        )}

                                                        {queryerror && (
                                                            <div className="p-2 bg-red-50 text-red-700 border border-red-200 rounded-md mt-2">
                                                                <p className="text-sm font-medium">{queryerror}</p>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </div>

                                            {/* Action Buttons */}
                                            <div className="flex items-center space-x-3">
                                                <button
                                                    onClick={executeQuery}
                                                    disabled={isButtonDisabled}
                                                    className="px-4 py-2 bg-gray-800 text-white text-sm font-medium rounded-md 
                                         hover:bg-gray-700 disabled:bg-gray-400 transition-colors duration-150
                                         focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2
                                         flex items-center space-x-2"
                                                >
                                                    <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                    </svg>
                                                    <span>Execute</span>
                                                </button>

                                                <button
                                                    onClick={submitQuery}
                                                    disabled={isButtonDisabled}
                                                    className="px-4 py-2 bg-blue-600 text-white text-sm font-medium rounded-md 
                                         hover:bg-blue-700 disabled:bg-blue-400 transition-colors duration-150
                                         focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2
                                         flex items-center space-x-2"
                                                >
                                                    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                    </svg>
                                                    <span>Submit</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Bottom Section: Results Table */}
                                {/* <div className="flex flex-col h-full bg-white border-t border-gray-200">
                                    <div className="flex-1 overflow-hidden">
                                        {results ? (
                                            <div className="h-full overflow-auto">
                                                <DataTable data={results} />
                                            </div>
                                        ) : (
                                            <div className="h-full flex items-center justify-center">
                                                <div className="text-center text-gray-500">
                                                    <svg className="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5}
                                                            d="M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4m0 5c0 2.21-3.582 4-8 4s-8-1.79-8-4" />
                                                    </svg>
                                                    <p className="mt-2 text-sm font-medium">Execute a query to see results</p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div> */}

                                {/* Bottom Section: Results Charts */}
                                {/* <FlexibleChart data={results} /> */}


                                {/* Bottom Section: Results View */}
                                <div className="flex flex-col h-full bg-white border-t border-gray-200">
                                    {/* Toggle Buttons */}
                                    <div className="flex justify-center space-x-4 p-1 bg-gray-100">
                                        <button
                                            onClick={() => setViewMode('table')}
                                            className={`px-4 py-1 rounded ${viewMode === 'table'
                                                ? 'bg-blue-500 text-white'
                                                : 'bg-white text-gray-700 border'
                                                }`}
                                        >
                                            Table View
                                        </button>
                                        <button
                                            onClick={() => setViewMode('chart')}
                                            className={`px-4 py-1 rounded ${viewMode === 'chart'
                                                ? 'bg-blue-500 text-white'
                                                : 'bg-white text-gray-700 border'
                                                }`}
                                        >
                                            Chart View
                                        </button>
                                    </div>

                                    <div className="flex-1 overflow-hidden">
                                        {results ? (
                                            viewMode === 'table' ? (
                                                <div className="h-full overflow-auto">
                                                    <DataTable data={results} />
                                                </div>
                                            ) : (
                                                <div className="h-full overflow-auto">
                                                    <FlexibleChart data={results} />
                                                </div>

                                            )
                                        ) : (
                                            <div className="h-full flex items-center justify-center">
                                                <div className="text-center text-gray-500">
                                                    <svg className="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5}
                                                            d="M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4m0 5c0 2.21-3.582 4-8 4s-8-1.79-8-4" />
                                                    </svg>
                                                    <p className="mt-2 text-sm font-medium">Execute a query to see results</p>
                                                </div>
                                            </div>
                                        )}
                                        {queryerror && (
                                            <div className="p-4 bg-red-50 text-red-700 border border-red-200 rounded-md mt-4">
                                                <p className="text-sm font-medium">{queryerror}</p>
                                            </div>
                                        )}
                                    </div>
                                </div>


                            </Split>
                        </div>
                    </div>

                </Split>


            </div >



        </div >
    );
}

export default SubscribersMain;




