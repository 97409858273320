import React from 'react';
import { useState, useEffect } from 'react';
import { Trophy, Medal } from 'lucide-react';

const CourseCertificate = ({
  userName,
  courseName = 'SQL Guroo',
  completionDate,
  score,
  totalQuestions
}) => {
  const [certificateDetails, setCertificateDetails] = useState({
    grade: '',
    color: ''
  });

  useEffect(() => {
    // Determine grade and color based on performance
    const calculateGrade = () => {
      const percentageScore = (score / totalQuestions) * 100;

      if (percentageScore >= 90) {
        setCertificateDetails({
          grade: 'Platinum',
          color: 'bg-gradient-to-r from-gray-600 to-gray-900'
        });
      } else if (percentageScore >= 80) {
        setCertificateDetails({
          grade: 'Gold',
          color: 'bg-gradient-to-r from-yellow-400 to-yellow-600'
        });
      } else if (percentageScore >= 70) {
        setCertificateDetails({
          grade: 'Silver',
          color: 'bg-gradient-to-r from-gray-200 to-gray-400'
        });
      } else {
        setCertificateDetails({
          grade: 'Bronze',
          color: 'bg-gradient-to-r from-orange-400 to-orange-600'
        });
      }
    };

    calculateGrade();
  }, [score, totalQuestions]);

  const downloadCertificate = async () => {
    try {
      const response = await fetch('https://sqlguroo.com/subapi/generate-certificate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          userName,
          courseName,
          completionDate,
          score,
          totalQuestions,
          grade: certificateDetails.grade
        })
      });

      if (!response.ok) {
        throw new Error('Certificate generation failed');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${userName}_SQL_Guroo_Certificate.pdf`;
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error downloading certificate:', error);
    }
  };

  return (
    <div className={`p-6 rounded-lg shadow-xl ${certificateDetails.color} text-white`}>
      <div className="text-center">
        <h1 className="text-3xl font-bold mb-4">SQL Guroo Certification</h1>

        <div className="flex justify-center mb-4">
          <Trophy size={64} className="text-yellow-300" />
        </div>

        <div className="mb-4">
          <h2 className="text-2xl font-semibold">{userName}</h2>
          <p>Has successfully completed the {courseName} Course</p>
        </div>

        <div className="grid grid-cols-2 gap-4 mb-4">
          <div>
            <strong>Completion Date:</strong>
            <p>{completionDate || new Date().toLocaleDateString()}</p>
          </div>
          <div>
            <strong>Performance:</strong>
            <p>{score} / {totalQuestions} Questions</p>
          </div>
        </div>

        <div className="flex items-center justify-center gap-2 mb-4">
          <Medal size={32} className="text-yellow-300" />
          <span className="text-xl font-bold">{certificateDetails.grade} Level Certificate</span>
        </div>

        <button
          onClick={downloadCertificate}
          className="bg-white text-black px-6 py-2 rounded-full hover:bg-gray-100 transition"
        >
          Download Certificate
        </button>
      </div>
    </div>
  );
};

export default CourseCertificate;