import React from 'react';
import axios from 'axios';

function AuthTester() {
    const checkAuth = async () => {
        try {
            const response = await axios.get('/auth/check-session', {
                withCredentials: true
            });
            console.log('Auth Check Response:', response.data);
        } catch (error) {
            console.log('Auth Check Error:', error.response?.data || error);
        }
    };

    const logout = async () => {
        try {
            await axios.post('/auth/logout', {}, {
                withCredentials: true
            });
            console.log('Logged out successfully');
            window.location.href = '/login';
        } catch (error) {
            console.log('Logout Error:', error.response?.data || error);
        }
    };

    return (
        <div>
            <button onClick={checkAuth}>Check Auth Status</button>
            <button onClick={logout}>Force Logout</button>
        </div>
    );
}

export default AuthTester;