import React, { createContext, useState, useEffect, useCallback } from "react";
import axios from "axios";

export const AuthContext = createContext();

const URL = 'https://sqlguroo.com';

export const AuthProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [userId, setUserId] = useState(
        () => localStorage.getItem("userId") || null
    );
    const [firstName, setFirstName] = useState(
        () => localStorage.getItem("firstName") || ""
    );
    const [lastName, setLastName] = useState(        // Added lastName
        () => localStorage.getItem("lastName") || ""
    );
    const [username, setUsername] = useState(
        () => localStorage.getItem("username") || ""
    );
    const [paidUser, setPaidUser] = useState(
        () => localStorage.getItem("paidUser") || ""
    );

    // Regular login
    const login = async (email, password) => {
        const response = await axios.post(
            `${URL}/auth/login`,
            { username: email, password },
            { withCredentials: true }
        );

        const { id, firstName: userFirstName, lastName: userLastName, username, paidUser } = response.data;
        updateAuthState(id, userFirstName, userLastName, username, paidUser);
    };

    // Google login handler
    const handleGoogleCallback = async () => {
        try {
            const response = await axios.get(
                `${URL}/auth/google/success`,
                { withCredentials: true }
            );

            const { id, firstName, lastName, username, paidUser } = response.data;
            updateAuthState(id, firstName, lastName, username, paidUser);
        } catch (error) {
            console.error('Error handling Google callback:', error);
            throw error;
        }
    };

    // Helper function to update auth state
    const updateAuthState = (id, firstName, lastName, username, paidUser) => {
        setUserId(id);
        setFirstName(firstName);
        setLastName(lastName);      // Added lastName
        setUsername(username);
        setPaidUser(paidUser);

        // Store in localStorage
        localStorage.setItem("userId", id);
        localStorage.setItem("firstName", firstName);
        localStorage.setItem("lastName", lastName);  // Added lastName
        localStorage.setItem("username", username);
        localStorage.setItem("paidUser", paidUser);
    };

    const logout = async () => {
        try {
            await axios.post(`${URL}/auth/logout`, {}, {
                withCredentials: true
            });
            clearAuthState();
        } catch (error) {
            console.error('Error during logout:', error);
            // Force clear cookies even if server logout fails
            document.cookie.split(";").forEach((c) => {
                document.cookie = c
                    .replace(/^ +/, "")
                    .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
            });
            clearAuthState();
        }
    };

    // Add clearAuthState as a useCallback to prevent recreation
    const clearAuthState = useCallback(() => {
        // Only clear if there's actually something to clear
        if (userId || firstName || lastName || username || paidUser) {
            setUserId(null);
            setFirstName("");
            setLastName("");
            setUsername("");
            setPaidUser("");

            localStorage.removeItem("userId");
            localStorage.removeItem("firstName");
            localStorage.removeItem("lastName");
            localStorage.removeItem("username");
            localStorage.removeItem("paidUser");

            // Only redirect if we're not already on the login page
            if (!window.location.pathname.includes('/login')) {
                window.location.href = '/login';
            }
        }
    }, [userId, firstName, lastName, username, paidUser]);

    // Update the useEffect with proper dependencies and guards
    useEffect(() => {
        let isMounted = true;

        const checkAuthStatus = async () => {
            // Don't check if we're already on the login page
            if (window.location.pathname.includes('/login')) {
                setIsLoading(false);
                return;
            }

            try {
                setIsLoading(true);
                const response = await axios.get(
                    `${URL}/auth/status`,
                    { withCredentials: true }
                );

                if (!isMounted) return;

                if (response.data.isAuthenticated && response.data.id) {
                    const { id, firstName, lastName, username, paidUser } = response.data;
                    updateAuthState(id, firstName, lastName, username, paidUser);
                } else {
                    clearAuthState();
                }
            } catch (error) {
                if (!isMounted) return;
                console.error('Error checking auth status:', error);
                clearAuthState();
            } finally {
                if (isMounted) {
                    setIsLoading(false);
                }
            }
        };

        checkAuthStatus();

        // Cleanup function
        return () => {
            isMounted = false;
        };
    }, [clearAuthState]); // Add clearAuthState to dependencies


    return (
        <AuthContext.Provider
            value={{
                userId,
                firstName,
                lastName,           // Added lastName
                username,
                paidUser,
                isLoading,  // Add loading state to context
                login,
                logout,
                handleGoogleCallback
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

